
























































































import {Component, Watch} from 'vue-property-decorator';
import {mixins} from 'vue-class-component';
import Notifications from '@/components/mixins/Notifications';
import VcSwitch from '@/components/VCSwitch.vue';
import {AwaySetting} from '@/domain/model/types';
import {settingsStore} from '@/store/modules/settings';
import rfdc from 'rfdc';
const clone = rfdc({proto: true})

@Component({
  name: 'settings-away-messages',
  components: {VcSwitch}
})
export default class AwayMessages extends mixins(Notifications) {
  away: AwaySetting = {
    active: true,
    closedMessage: null,
    awayMessage: null
  }

  // @Watch('away', {deep: true, immediate: false})
  // onAwayChanged(after, before) {
  //   this.checkDisabled()
  // }
  //
  // checkDisabled() {
  //   this.disabled = this.compareChange() ? false : true
  // }

  compareChange() {
    return true
  }

  async onSave() {
    if (!this.compareAway()) {
      await settingsStore.changeAway(clone(this.away))
    }
  }

  compareAway() {
    return JSON.stringify(this.away) === JSON.stringify(settingsStore.businessSettings?.away)
  }

  get disabled() {
    return this.compareAway()
  }

  onDiscard() {
    this.away = settingsStore.businessSettings?.away
  }

  init() {
    this.away = clone(settingsStore.businessSettings?.away)
  }

  created() {
    this.init()
  }
}
