import store from '@/store';
import {Action, getModule, Module, Mutation, VuexModule} from 'vuex-module-decorators';
import {
  NotificationsPositionHorizontal,
  NotificationsPositionVertical,
  NotificationsState,
  NotificationsSystem,
  NotificationsType, NotificationTimeout, UndoSystem
} from '@/domain/model/types';

const defaultState: NotificationsState = {
  notificationsSystem: []
}

@Module({name: 'notifications-module', store, dynamic: true})
export default class NotificationsModule extends VuexModule {
  private _notificationsSystem: NotificationsSystem[] = defaultState.notificationsSystem
  private _processed: NotificationsSystem[] = []
  private _undoSystem: UndoSystem[] = []
  private _undoProcessed: string[] = []
  private audio = new Audio()

  get notificationsSystem() {
    return this._notificationsSystem;
  }

  get processed() {
    return this._processed
  }

  get undo() {
    return this._undoSystem
  }

  get undoProcessed() {
    return this._undoProcessed
  }

  @Mutation
  public pushUndoSystem(undo: UndoSystem) {
    this._undoSystem.unshift(undo)
  }

  @Mutation
  public addUndoProcessed(id: string) {
    this._undoProcessed.unshift(id)
  }

  @Mutation
  public removeUndoSystem(id: string) {
    this._undoSystem.splice(this._undoSystem.findIndex((item) => item.id === id), 1)
  }

  @Mutation
  public removeUndoProcessed(id: string) {
    this._undoProcessed.splice(this._undoProcessed.findIndex((item) => item === id), 1)
  }

  @Mutation
  public pushNotificationSystem(notification: NotificationsSystem) {
    this._notificationsSystem.unshift(notification);
  }

  @Mutation
  public shiftNotificationSystem() {
    this._notificationsSystem.pop();
  }

  @Mutation
  public addProcessed(notification: NotificationsSystem) {
    this._processed.unshift(notification)
  }

  @Mutation
  public removeProcessed() {
    this._processed.pop()
  }

  @Action
  public showUndo(undo) {
    this.pushUndoSystem(undo)
  }

  @Action
  public unsetUndo(id: string) {
    this.removeUndoSystem(id)
    this.removeUndoProcessed(id)
  }

  @Action
  public showNotification(notification) {
    this.pushNotificationSystem(notification);
  }

  @Action
  public unsetNotification() {
    this.shiftNotificationSystem()
    this.removeProcessed()
  }

  @Action
  public showInfo(text: string) {
    const notification: NotificationsSystem = {
      type: NotificationsType.INFO,
      text,
      timeout: NotificationTimeout.SHORT,
      position: {
        horizontal: NotificationsPositionHorizontal.RIGHT,
        vertical: NotificationsPositionVertical.TOP
      }
    };
    this.pushNotificationSystem(notification);
  }

  @Action
  public showIssue(text: string) {
    const notification: NotificationsSystem = {
      type: NotificationsType.ISSUE,
      text,
      timeout: NotificationTimeout.SHORT,
      position: {
        horizontal: NotificationsPositionHorizontal.RIGHT,
        vertical: NotificationsPositionVertical.TOP
      }
    };
    this.pushNotificationSystem(notification);
  }

  @Action
  public async notify(type: string) {
    this.setSoundSource(require(`../../assets/sound/${type}.mp3`))
    await this.playSound()
  }

  @Mutation
  private setSoundSource(src: string) {
    this.audio.src = src
  }

  @Mutation
  private async playSound() {
    try {
      await this.audio.play()
    } catch (e: any) {
      console.error(e)
    }
  }
}

export const notificationsStore = getModule(NotificationsModule);
